import React, { useEffect, useState, useCallback } from "react";
import {
  Layout,
  List,
  Typography,
  Input,
  Button,
  Form,
  Spin,
  Avatar,
  message as antMessage,
  Popover,
} from "antd";
import {
  SendOutlined,
  UserOutlined,
  StarOutlined,
  StarFilled,
} from "@ant-design/icons";
import axios from "axios";

const { Header, Content, Footer, Sider } = Layout;
const { Text } = Typography;
const { TextArea } = Input;

const WhatsAppInterface = () => {
  const [messages, setMessages] = useState({});
  const [contacts, setContacts] = useState([]);
  const [importantContacts, setImportantContacts] = useState([]);
  const [selectedContact, setSelectedContact] = useState("");
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [userDetails, setUserDetails] = useState({}); // State for storing user details

  const fetchImportantContacts = useCallback(async () => {
    try {
      const response = await axios.get(
        "/.netlify/functions/getImportantContacts"
      );

      if (response.data.success) {
        setImportantContacts(response.data.importantContacts);
      } else {
        console.error(
          "Failed to fetch important contacts:",
          response.data.error
        );
      }
    } catch (error) {
      console.error("Error fetching important contacts:", error.message);
    }
  }, []);

  const fetchMessages = useCallback(async () => {
    try {
      const [receivedResponse, sentResponse] = await Promise.all([
        fetch("/.netlify/functions/getWhatsAppMessages"),
        fetch("/.netlify/functions/getSentMessages"),
      ]);

      const receivedData = await receivedResponse.json();
      const sentData = await sentResponse.json();

      const combinedMessages = {};
      const userDetailsMap = {};

      receivedData.messages.forEach((msg) => {
        if (!combinedMessages[msg.from]) {
          combinedMessages[msg.from] = [];
        }
        combinedMessages[msg.from].push({
          id: msg.id,
          ...msg,
          direction: "incoming",
        });
        // Store userName in userDetailsMap
        if (msg.userName) {
          userDetailsMap[msg.from] = msg.userName;
        }
      });

      sentData.sentMessages.forEach((msg) => {
        if (!combinedMessages[msg.to]) {
          combinedMessages[msg.to] = [];
        }
        combinedMessages[msg.to].push({
          id: msg.id || `${msg.timestamp}-${msg.text}`,
          ...msg,
          direction: "outgoing",
        });
      });

      for (let contact in combinedMessages) {
        combinedMessages[contact].sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
        );
      }

      setMessages(combinedMessages);
      setUserDetails(userDetailsMap);
      setContacts(Object.keys(combinedMessages));
      if (!selectedContact && Object.keys(combinedMessages).length > 0) {
        setSelectedContact(Object.keys(combinedMessages)[0]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      antMessage.error("Failed to load messages.");
    } finally {
      setLoading(false);
    }
  }, [selectedContact]);

  useEffect(() => {
    setLoading(true);
    fetchImportantContacts();
    fetchMessages();

    const interval = setInterval(fetchMessages, 5000);
    return () => clearInterval(interval);
  }, [fetchImportantContacts, fetchMessages]);

  const toggleImportantContact = async (contact) => {
    const isImportant = importantContacts.includes(contact);
    const markAsImportant = !isImportant;

    try {
      const response = await axios.post(
        "/.netlify/functions/updateImportantContact",
        {
          phoneNumber: contact,
          markAsImportant,
        }
      );

      if (response.data.success) {
        setImportantContacts(
          (prev) =>
            markAsImportant
              ? [...prev, contact] // Add to important
              : prev.filter((item) => item !== contact) // Remove from important
        );
      } else {
        antMessage.error("Failed to update important status.");
      }
    } catch (error) {
      console.error("Error toggling important status:", error.message);
      antMessage.error("Failed to toggle important status.");
    }
  };

  const sendMessage = async (input) => {
    if (!selectedContact) {
      antMessage.error("Select a contact to send a message.");
      return;
    }

    // Deconstruct the `message` field if the input is an object
    const message =
      typeof input === "object" && input !== null ? input.message : input;

    setSending(true);
    try {
      await axios.post("/.netlify/functions/sendWhatsAppMessage", {
        to: selectedContact,
        text: message,
      });

      const newMessage = {
        id: `${new Date().toISOString()}-${message}`,
        from: "me",
        to: selectedContact,
        text: message,
        timestamp: new Date().toISOString(),
        direction: "outgoing",
      };

      setMessages((prevMessages) => ({
        ...prevMessages,
        [selectedContact]: [
          ...(prevMessages[selectedContact] || []),
          newMessage,
        ],
      }));
      form.resetFields();
    } catch (error) {
      console.error("Error sending message:", error);
      antMessage.error("Failed to send message.");
    } finally {
      setSending(false);
    }
  };

  const handleContactSelection = async (contact) => {
    setSelectedContact(contact);

    // Get unread messages for the selected contact
    const unreadMessages = messages[contact]?.filter(
      (msg) => msg.direction === "incoming" && !msg.read
    );

    if (unreadMessages?.length) {
      try {
        // Update each unread message in FaunaDB
        const updatePromises = unreadMessages.map((msg) =>
          axios.post("/.netlify/functions/updateWhatsAppMessage", {
            messageId: msg.id,
            read: true,
          })
        );

        // Wait for all updates to complete
        await Promise.all(updatePromises);

        // Update the frontend state to reflect the read status
        setMessages((prevMessages) => {
          const updatedMessages = { ...prevMessages };
          if (updatedMessages[contact]) {
            updatedMessages[contact] = updatedMessages[contact].map((msg) =>
              msg.direction === "incoming" && !msg.read
                ? { ...msg, read: true }
                : msg
            );
          }
          return updatedMessages;
        });
      } catch (error) {
        console.error("Error updating messages as read:", error);
      }
    }
  };

  const [form] = Form.useForm();

  return (
    <Layout style={{ height: "100vh" }}>
      <Sider width={300} style={{ background: "#f5f5f5" }}>
        <Header
          style={{ background: "#008069", color: "#fff", textAlign: "center" }}
        >
          Contacts
        </Header>
        <List
          itemLayout="horizontal"
          dataSource={contacts.sort((a, b) => {
            const latestMessageA =
              messages[a] && messages[a][messages[a].length - 1]?.timestamp;
            const latestMessageB =
              messages[b] && messages[b][messages[b].length - 1]?.timestamp;

            return new Date(latestMessageB) - new Date(latestMessageA);
          })}
          renderItem={(contact) => {
            const isImportant = importantContacts.includes(contact);
            const latestMessage =
              messages[contact] &&
              messages[contact][messages[contact].length - 1];
            const previewText =
              latestMessage?.text ||
              (latestMessage?.media?.caption ? "[Media]" : "No messages yet");

            return (
              <List.Item
                onClick={() => handleContactSelection(contact)}
                style={{
                  padding: "16px",
                  cursor: "pointer",
                  backgroundColor:
                    contact === selectedContact
                      ? "#e0f7fa"
                      : isImportant
                      ? "#ffebcc" // Highlight important contacts
                      : "transparent",
                }}
                actions={[
                  <Button
                    type="link"
                    icon={isImportant ? <StarFilled /> : <StarOutlined />}
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent triggering the onClick for the List.Item
                      toggleImportantContact(contact);
                    }}
                  />,
                ]}
              >
                <List.Item.Meta
                  avatar={<Avatar icon={<UserOutlined />} />}
                  title={
                    <Text strong>
                      {contact}{" "}
                      {latestMessage?.direction === "incoming" &&
                        latestMessage?.read === false && (
                          <span style={{ color: "red", marginLeft: "8px" }}>
                            NEW
                          </span>
                        )}
                    </Text>
                  }
                  description={
                    <Text
                      type="secondary"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "block",
                      }}
                    >
                      <small>{previewText}</small>
                    </Text>
                  }
                />
              </List.Item>
            );
          }}
        />
      </Sider>

      <Layout>
        <Header
          style={{ background: "#008069", color: "#fff", padding: "0 20px" }}
        >
          <Text style={{ color: "#fff" }}>Chat with {selectedContact}</Text>
        </Header>
        <Layout style={{ display: "flex", flexDirection: "row" }}>
          <Content
            style={{
              padding: "20px",
              background: "#e5ddd5",
              overflowY: "auto",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {loading ? (
              <Spin tip="Loading messages..." />
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={messages[selectedContact] || []}
                renderItem={(item) => (
                  <div
                    className={`message-bubble ${
                      item.direction === "incoming" ? "incoming" : "outgoing"
                    }`}
                    style={{
                      marginBottom: "10px",
                      maxWidth: "60%",
                      backgroundColor:
                        item.direction === "incoming" ? "#fff" : "#dcf8c6",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                  >
                    {item.text && <Text>{item.text}</Text>}
                    <div style={{ textAlign: "right", marginTop: 5 }}>
                      <Text style={{ fontSize: "0.8em", color: "#888" }}>
                        {new Date(item.timestamp).toLocaleTimeString()}
                      </Text>
                    </div>
                  </div>
                )}
              />
            )}
          </Content>
          {/* Adjacent Section */}
          <Sider
            width={300}
            style={{
              background: "#f9f9f9",
              borderLeft: "1px solid #ddd",
              padding: "20px",
            }}
          >
            <Header style={{ background: "#f0f0f0", textAlign: "center" }}>
              Details
            </Header>
            <div>
              <Text strong>Contact:</Text> <br />
              <Text>{selectedContact || "Select a contact"}</Text>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Text strong>Name:</Text> <br />
              <Text>{userDetails[selectedContact] || "Unknown"}</Text>
            </div>
            <Popover
              placement="bottom"
              content={
                <div style={{ maxWidth: "250px" }}>
                  <p> Thanks for messaging customer service @ Tech For Good</p>
                  <p> How may I be of assistance?</p>
                </div>
              }
              title="Intro Message"
            >
              <Button
                type="primary"
                style={{ marginTop: "20px", width: "100%" }}
                onClick={() =>
                  sendMessage(
                    "Thanks for messaging customer service @ Tech For Good\nHow may I be of assistance?"
                  )
                }
              >
                Send "Intro"
              </Button>
            </Popover>
          </Sider>
        </Layout>
        <Footer
          style={{
            background: "#f0f0f0",
            padding: "10px 20px",
            position: "sticky",
            bottom: 0,
            width: "100%",
            zIndex: 1000,
            borderTop: "1px solid #ccc",
          }}
        >
          <Form
            form={form}
            onFinish={sendMessage}
            layout="inline"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              width: "100%",
            }}
          >
            <Form.Item
              name="message"
              rules={[{ required: true, message: "Enter a message!" }]}
              style={{ flexGrow: 1, marginBottom: 0 }}
            >
              <TextArea
                rows={1}
                placeholder="Type a message..."
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            </Form.Item>
            <Button
              type="primary"
              shape="circle"
              icon={<SendOutlined />}
              htmlType="submit"
              loading={sending}
            />
          </Form>
        </Footer>
      </Layout>
    </Layout>
  );
};

export default WhatsAppInterface;
